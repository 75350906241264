import React, { useState, useEffect, useCallback } from "react";
import Web3, { net } from "web3";
import abi from "./abi.json";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import detectEthereumProvider from "@metamask/detect-provider";


let contractAddress = "0xD150e07f602bf3239BE3DE4341E10BE1678a3f8b"; // Add the contract address here
let network = "1";
let networkName = "Ethereum Mainnet";
let doingCheck = true;

const testMode = (window.location.hostname === "localhost");
if(testMode) {
  console.log("Test mode");

  contractAddress = "0xD150e07f602bf3239BE3DE4341E10BE1678a3f8b"; // Add the contract address here
  network = "11155111";
  networkName = "Sepolia";
}

// Helper functions and hooks
const useAsyncError = () => {
  const [_, setError] = useState();
  return useCallback((e) => {
    setError(() => {
      throw e;
    });
  }, []);
};


const App = () => {
  const [web3, setWeb3] = useState(null);
  const [contract, setContract] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [amount, setAmount] = useState(1);
  const [stakeAmount, setStakeAmount] = useState(1);
  const [stakingAmount, setStakingAmount] = useState(0);
  const [totalOwned, setTotalOwned] = useState(0);
  const [totalStaked, setTotalStaked] = useState("Loading...");
  const [reward, setReward] = useState("Loading...");
  const [pricePerToken, setPricePerToken] = useState(0.00025);
  const [bonusRate, setBonusRate] = useState(10);
  const [isConnected, setIsConnected] = useState(false);
  const [logMessages, setLogMessages] = useState("");
  const [timeCheck, setTimeCheck] = useState(0);
  const [tokensToBeSold, setTokensToBeSold] = useState(-1);
  const [tokensSoldPC, setTokensSoldPC] = useState(0); 
  const throwError = useAsyncError();
  const [useWalletConnect, setUseWalletConnect] = useState(false);
  const [hasProvider, setHasProvider] = useState(false);

	useEffect(() => {
		const getProvider = async () => {
			const provider = await detectEthereumProvider({ silent: true });
			console.log(provider);
			setHasProvider(Boolean(provider)); // transform provider to true or false
		};

		getProvider();
	}, []);


  const handleErrors = useCallback(
    (e) => {
      
      console.log(e);
        if (e.reason) {
          console.error("Revert reason:", e.reason);
        }
      if (typeof e !== "string") {
        e = e.message;
      }
      //check if same error message was last error message
      if (
        logMessages !== "" &&
        logMessages.props &&
        logMessages.props.children.length > 0
      ) {
        const lastError =
          logMessages.props.children[logMessages.props.children.length - 1];

        if (lastError.props && lastError.props.children === e) {
          return;
        }
      }

      setLogMessages((logMessages) => (
        <>
          {logMessages}
          <div className="error_msg">{e}</div>
        </>
      ));
    },
    [logMessages, setLogMessages]
  );


  //function to check correct network
  const checkNetwork = useCallback(async () => {
    try {
      var networkId = await web3.eth.net.getId();
      if(networkId === undefined) {
        //get network id from provider
        networkId = await web3.eth.getChainId();
      }

      if (networkId === undefined) {
        handleErrors("Please connect to the " + networkName + " network!");
        console.log("Wrong network.", networkId, network);
        // setIsConnected(false);
        return;
      }

      //convert from bigint
      networkId = networkId.toString();
      if (networkId !== network) {
        handleErrors("Please connect to the " + networkName + " network.");
        console.log("Wrong network", networkId, network);
        //   setIsConnected(false);
        return;
      }
      //console.log("Connected to network", networkId, network);
      // setIsConnected(true);
      return true;
    } catch (e) {
      console.log("Error checking network", e);
      handleErrors("Please connect to the " + networkName + " network");
      console.log("Wrong network", networkId, network);
      //  setIsConnected(false);
      return false;
    }
  }, [web3, handleErrors]); // Add dependencies that checkNetwork relies on

  useEffect(() => {
    document.getElementsByClassName("loading_overlay")[0].style.display =
      "none";

    //check every 15 seconds if connected to the right network
    const interval = setInterval(() => {
      //check for window focus
      if (!document.hasFocus()) return;
      if(doingCheck) return;
      setTimeCheck(Date.now());
    }, 20000);

    return () => clearInterval(interval);
  }, []);

  useEffect(
    () =>  {
      console.log("useEffect", contract, accounts, isConnected, timeCheck);
      if (!isConnected) return;
      if (contract && accounts.length > 0) {
        doingCheck = true;
        try{
          fetchThings();
        }catch(e) {
          console.log("Error fetching things", e);
        }
        doingCheck = false;
      }
    },
    [isConnected, timeCheck, contract, accounts]
  );

  useEffect(() => {
    setStakeAmount(totalOwned);
  }, [totalOwned]);

  useEffect(() => {
    clearErrors();
  }, [isConnected === true]);

  function handleInfo(result) {
    if (result === undefined) {
      return;
    }
    if (result === null) {
      return;
    }
    if (result === "") {
      return;
    }
    if (result === "0x") {
      return;
    }
    console.log(result);

    setLogMessages((logMessages) => (
      <>
        {logMessages}
        <div className="info_msg">{result}</div>
      </>
    ));
  }

  function clearErrors() {
    if (logMessages === "") return;
    if (!logMessages.props) return;
    if (!logMessages.props.children) return;
    if (logMessages.props.children.length === 0) return;

    //filter out error messages from logMessages
    const infoMessages = logMessages.props.children.filter((msg) => {
      try {
        return msg.props.className === "info_msg";
      } catch (e) {
        return false;
      }
    })
    setLogMessages(infoMessages);
  }

 
const handleAsyncMethod = (
  asyncMethod,
  maxRetries = 4,
  initialDelay = 3500
) => {
  return async (...args) => {
    let retries = 0;
    let delay = initialDelay;

    while (retries < maxRetries) {
      try {
        if (!(await checkNetwork())) {
          throw new Error("Wrong network");
        }

        const result = await asyncMethod(...args);
        handleInfo(result);
        return result;
      } catch (err) {
        if (err.message && err.message.includes("execution reverted")) {
          retries++;
          console.warn(`Retrying ${retries}/${maxRetries} after ${delay}ms`);
          await new Promise((resolve) => setTimeout(resolve, delay));
          delay *= 2; // Exponential backoff
        } else {
          handleErrors(err);
          break;
        }
      }
    }
  };
};


  const getLatestBlock = async () => {
    return await web3.eth.getBlock("latest");
  };

  async function handleContractMethod(
    contract,
    methodName,
    params = [],
    options = {}
  ) {
    try {
      const gasEstimate = await contract.methods[methodName](
        ...params
      ).estimateGas({ ...options });
      const bufferedGasEstimate =
        (window.BigInt(gasEstimate) * window.BigInt(120)) / window.BigInt(100); // Adding 20%
      const finalGasEstimate = Number(bufferedGasEstimate);

      const latestBlock = await getLatestBlock();
      const baseFeePerGas = window.BigInt(latestBlock.baseFeePerGas);

      // Add a margin to the base fee 
      const margin = window.BigInt(2000000000); // Example value in WEI; you can adjust

      const maxPriorityFeePerGas = window.BigInt(2000000000); // Example value in WEI; you can adjust
      const maxFeePerGas = baseFeePerGas + maxPriorityFeePerGas + margin;

      const data = contract.methods[methodName](...params).encodeABI();
      const tx = await web3.eth.sendTransaction({
        ...options,
        to: contract.options.address,
        gas: finalGasEstimate,
        maxPriorityFeePerGas: maxPriorityFeePerGas.toString(),
        maxFeePerGas: maxFeePerGas.toString(),
        data,
      });
      return tx;
    } catch (error) {
      console.error("An error occurred:", error);
      throw error;
    }
  }

  const initializeProvider = useCallback(async (_uwc) => {
      let provider;

    try{
      if (window.ethereum && !_uwc) {
        provider = window.ethereum;
        console.log("window.ethereum", provider);
        await provider.request({ method: "eth_requestAccounts" });
                

      } else if (window.web3 && !_uwc) {
        provider = window.web3.currentProvider;
        console.log("window.web3", provider);

      } else {


        provider = await EthereumProvider.init({
          projectId: "b7151eb7ba4e30e69839e709db44909f", // required
          chains: [1], // required
          showQrModal: true, // requires @walletconnect/modal
        });
        
        await provider.connect();
        setUseWalletConnect(true);
        

      }
    } catch (error) {
      handleErrors(error);
    }
          return provider;

  }, []);

  const handleNetworkConnection = useCallback(
    async (web3Instance) => {
      try {
        console.log("handleNetworkConnection", web3Instance);
        const contractInstance = new web3Instance.eth.Contract(
          abi,
          contractAddress
        );
        setContract(contractInstance);

        let accounts;

        if(!web3Instance.currentProvider) {
            accounts = await window.ethereum.request({   /* New */
              method: "eth_requestAccounts",                 /* New */
            })   
          }else{
            //request access
            if (web3Instance.currentProvider.enable) {
              accounts = await web3Instance.currentProvider.enable();
            } 


            if (web3Instance.currentProvider.isMetaMask) {
              //accounts = await web3Instance.eth.getAccounts();
                accounts = await window.ethereum.request({
                  /* New */ method: "eth_requestAccounts" /* New */,
                });   
            } else {
              accounts = await web3Instance.eth.requestAccounts();
            }
          }
        setAccounts(accounts);
        //check are any accounts connected
        if (accounts.length === 0) {
          console.log("No accounts connected");
          setIsConnected(false);
          return;
        }

        //    accounts = await window.ethereum.request({
        //   method: "eth_requestAccounts",
        // });

        // setAccounts(accounts);
        doingCheck = false;
        setIsConnected(true);
      } catch (error) {
        handleErrors(error);
      }
    },
    [checkNetwork]
  );

  // useEffect(() => {
  //   const initWeb3 = async () => {
  //     const provider = await initializeProvider(false);
  //     if (provider) {
  //       const web3Instance = new Web3(provider);
  //       setWeb3(web3Instance);
  //       const contractInstance = new web3Instance.eth.Contract(
  //         abi,
  //         contractAddress
  //       );
  //       setContract(contractInstance);
  //     }
  //   };
  //   initWeb3();
  // }, []); // Empty dependency array ensures this runs only once

  // // Handle network connection
  // useEffect(() => {
  //   if (web3) {
  //     // Only run this if web3 is initialized
  //     handleNetworkConnection(web3);
  //   }
  // }, [web3]); // Runs when `web3` changes

  const connectWallet = useCallback(async (_uwc) => {
    const provider = await initializeProvider(_uwc);
    const web3Instance = new Web3(provider);

    await handleNetworkConnection(web3Instance);
    setWeb3(web3Instance);

    
  }, [initializeProvider, handleNetworkConnection]);

  //get tokensToBeSold public variable from contract (not a method)
  const fetchTokensToBeSold = handleAsyncMethod(async () => {
    const tokensToBeSold = await contract.methods.tokensToBeSold().call({ from: accounts[0] });
    console.log("tokensToBeSold", tokensToBeSold);
    setTokensToBeSold(Web3.utils.fromWei(tokensToBeSold, "ether"));
  });

  const fetchTotalStaked = handleAsyncMethod(async () => {
    await checkNetwork();
    const total = await contract.methods.totalStaked().call({ from: accounts[0] });
    setTotalStaked(Web3.utils.fromWei(total, "ether") + " BITX");
  });

  const fetchThings = handleAsyncMethod(async () => {
    await fetchTotalStaked();
    await fetchStakingAmount();
    await fetchPriceAndBonus();
    await fetchReward();
    await fetchTokensToBeSold();
  });

  const fetchStakingAmount = handleAsyncMethod(async () => {
    
      const result = await contract.methods
        .viewStakedAmount(accounts[0])
        .call({ from: accounts[0] });
      try{
      const stakingAmount = result[0].toString();
      const totalOwned = result[1].toString();
      console.log("staked, unstaked", stakingAmount, totalOwned);
      setStakingAmount(Web3.utils.fromWei(stakingAmount.toString(), "ether"));
      setTotalOwned(Web3.utils.fromWei(totalOwned.toString(), "ether"));
    } catch (err) {
      console.error("An error occurred:", err);
      //setIsConnected(false);
    }
  });

  const fetchPriceAndBonus = handleAsyncMethod(async () => {
    
      const result = await contract.methods
        .getPriceAndBonus()
        .call({ from: accounts[0] });
        try {
          //console.log("Result:", result);
          const pricePerToken = result[0];
          const bonusRate = result[1];
          setPricePerToken(
            Web3.utils.fromWei(pricePerToken.toString(), "ether")
          );
          setBonusRate(bonusRate.toString());
        } catch (err) {
          console.error("An error occurred:", err);
          if (err.reason) {
            console.error("Revert reason:", err.reason);
          }
          setIsConnected(false);
        }
  });

  const fetchReward = handleAsyncMethod(async () => {
    const reward = await contract.methods.viewRewards(accounts[0]).call({ from: accounts[0] });
    setReward(Web3.utils.fromWei(reward, "ether") + " ETH");
  });

  const approveTokens = handleAsyncMethod(async (amount) => {
    amount = Web3.utils.toWei(amount.toString(), "ether");
    await handleContractMethod(contract, "approve", [accounts[0], amount], {
      from: accounts[0],
    });
  });

  const purchaseTokens = handleAsyncMethod(async () => {
    const amountInWei = Web3.utils.toWei(amount.toString(), "ether");
    await handleContractMethod(contract, "purchase", [], {
      from: accounts[0],
      value: amountInWei,
    });
  });

  const stakeTokens = handleAsyncMethod(async () => {
    const amountInWei = Web3.utils.toWei(stakeAmount.toString(), "ether");
    await handleContractMethod(contract, "stake", [amountInWei], {
      from: accounts[0],
    });
  });

  const claimRewards = handleAsyncMethod(async () => {
    await handleContractMethod(contract, "claimRewards", [], {
      from: accounts[0],
    });
  });


  const instantUnstakeTokens = handleAsyncMethod(async () => {
    await handleContractMethod(contract, "onDemandUnstake", [], {
      from: accounts[0],
    });
  });

  const unstakeTokens = handleAsyncMethod(async () => {
    await handleContractMethod(contract, "unstakeTokens", [], {
      from: accounts[0],
    });
  });

  const withdrawUnstaked = handleAsyncMethod(async () => {
    await handleContractMethod(contract, "withdrawUnstaked", [], {
      from: accounts[0],
    });
  });

  //bonus 10% for every 1 ETH, maximum 100% bonus
  let tokenAmount = amount / pricePerToken;
  let bonusPct = (Math.floor(amount * 10) / 10) * bonusRate;
  bonusPct = bonusPct > 100 ? 100 : bonusPct;

  const bonusAmt = (tokenAmount * bonusPct) / 100;
  const total = tokenAmount + bonusAmt;

  // Calculate amount unstaked
  const amountUnstaked = totalOwned;

  const totalSupply = 1000000 / 2;
  const tokensSold = totalSupply - tokensToBeSold;
  
  useEffect(() => {
    const _tokensSold = totalSupply - tokensToBeSold;

    setTokensSoldPC((_tokensSold / totalSupply) * 100);
  }, [tokensSold, totalSupply, tokensToBeSold]);



  return (
		<div className="container">
			<div>
				<h4>
					<img src="bitxtlogo.png" style={{ width: "200px" }} alt="Bitx logo" />
				</h4>
				<h4>
					<a
						href="https://etherscan.io/token/0xd150e07f602bf3239be3de4341e10be1678a3f8b"
						target="_blank"
						rel="noreferrer">
						0xD150e07f602bf3239BE3DE4341E10BE1678a3f8b
					</a>
					<br /> No Taxes. Fixed Supply. Pre-sale open to all!{" "}
					<a
						href="https://github.com/Rotwang9000/bitx_live/wiki/Tokenomics"
						target="_blank">
						More Tokenomics info...
					</a>
				</h4>
			</div>
			<div id="errorbox">{logMessages}</div>
			<div>
				{!isConnected ? (
					<>
						<h3>Connect your wallet to purchase and stake $Bitx Tokens</h3>
             { hasProvider &&
						<button
							onClick={() => {
								connectWallet(false);
							}}>
							Connect Wallet, eg. Metamask
						</button>
            }
						<br />
						<button
							onClick={() => {
								connectWallet(true);
							}}>
							Connect WalletConnect
						</button>
					</>
				) : (
					<div>
						<h1>Buy BITX Tokens</h1>
						<label htmlFor="amount">
							Amount to Purchase:
							<br /> <i>Slide the slider to change between 0.0025 and 10E</i>
							<br />
						</label>
						<input
							type="range"
							id="amount"
							name="amount"
							min="0.0025"
							max="10"
							step="0.0025"
							value={amount}
							onChange={(e) => setAmount(e.target.value)}
						/>
						<p>Amount: {amount} ETH</p>
						<p>Price Per Token: {pricePerToken} ETH</p>
						<p>Bonus Rate: {bonusPct}%</p>
						<p>Total: {total} BITX</p>
						<button onClick={purchaseTokens}>Purchase</button>

						<div className="progress">
							<div
								className="progress-bar"
								role="progressbar"
								style={{ width: tokensSoldPC + "%" }}
								aria-valuenow={tokensSold}
								aria-valuemin="0"
								aria-valuemax={totalSupply}
								title={tokensSold + " / " + totalSupply}>
								{tokensToBeSold === -1 ? "loading..." : tokensSoldPC + "%"}
							</div>
						</div>
{/*             
						<h1>Register Address for No-Gas-Required Swaps</h1>
						<h3>
							<i>
								Token stakers can register any address that will get lent enough
								ETH for gas to transfer a token to ETH.
							</i>
						</h3>
						<input
							type="text"
							value={accounts[0]}
							id="addr_ngr"
							className="inputtxt"
						/>
						<button disabled>Coming Soon</button> */}

						<h1>Stake BITX Tokens</h1>
						<label htmlFor="stakeAmount">
							Amount to Stake:
							<br />
						</label>
						<input
							type="range"
							id="stakeAmount"
							name="stakeAmount"
							min="1"
							max={amountUnstaked}
							step="1"
							value={stakeAmount}
							onChange={(e) => setStakeAmount(e.target.value)}
						/>
						<p>Stake Amount: {stakeAmount} BITX</p>
						<br />
						<div>
							<button onClick={() => approveTokens(stakeAmount)}>
								Approve
							</button>{" "}
							- Required to stake
						</div>
						<div>
							<button onClick={() => stakeTokens()}>Stake</button>- You will be
							eligible for rewards!
						</div>
						<div>
							<button onClick={() => claimRewards()}>Claim Rewards</button>
							{reward}
						</div>
						<div>
							<button onClick={unstakeTokens}>Unstake All</button> Unstaking
							takes 15 days, Stake more to Cancel Unstaking
						</div>
						<div>
							<button onClick={withdrawUnstaked}>Withdraw Unstaked</button> When
							the 15 days are up. Automatically claims rewards.
						</div>
						<div>
							<button onClick={instantUnstakeTokens}>
								Instant Unstake and Withdraw
							</button>{" "}
							- 8% fee, no rewards
						</div>
						<h1>Staking Information</h1>
						<p>Total Staked: {totalStaked}</p>
						<p>Your Staked: {stakingAmount}</p>
						<p>Amount Unstaked: {amountUnstaked}</p>
						<p>Your Reward: {reward}</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default App;
